import { default as _91_46_46_46uri_93FmSYeNXvC5Meta } from "/vercel/path0/pages/[...uri].vue?macro=true";
import { default as searchkRoPkDBbjJMeta } from "/vercel/path0/pages/[[lang]]/search.vue?macro=true";
import { default as _91_46_46_46all_93H0MhJdxsYwMeta } from "/vercel/path0/pages/[[lang]]/shop/[...all].vue?macro=true";
import { default as address2YPObEUJW8Meta } from "/vercel/path0/pages/[[lang]]/shop/account/address.vue?macro=true";
import { default as brandszsXuVXGUHFMeta } from "/vercel/path0/pages/[[lang]]/shop/account/brands.vue?macro=true";
import { default as _91_91id_93_930WiuAkUeCYMeta } from "/vercel/path0/pages/[[lang]]/shop/account/downloads/[[id]].vue?macro=true";
import { default as _91id_93zT4oyjsEaCMeta } from "/vercel/path0/pages/[[lang]]/shop/account/orders/[id].vue?macro=true";
import { default as indexM4N6U1O4szMeta } from "/vercel/path0/pages/[[lang]]/shop/account/orders/index.vue?macro=true";
import { default as profiletlLTQuDee2Meta } from "/vercel/path0/pages/[[lang]]/shop/account/profile.vue?macro=true";
import { default as index5nsMtRuQ1dMeta } from "/vercel/path0/pages/[[lang]]/shop/account/recover/index.vue?macro=true";
import { default as passwordgg8RJUmFfdMeta } from "/vercel/path0/pages/[[lang]]/shop/account/recover/password.vue?macro=true";
import { default as failure3S0jwPb5p3Meta } from "/vercel/path0/pages/[[lang]]/shop/checkout/[id]/failure.vue?macro=true";
import { default as successev0Fuos7ehMeta } from "/vercel/path0/pages/[[lang]]/shop/checkout/[id]/success.vue?macro=true";
import { default as cart7yUeZIbEicMeta } from "/vercel/path0/pages/[[lang]]/shop/checkout/cart.vue?macro=true";
import { default as indexNTWEvTkKWSMeta } from "/vercel/path0/pages/[[lang]]/shop/checkout/index.vue?macro=true";
import { default as loginHoMyJwtuv2Meta } from "/vercel/path0/pages/[[lang]]/shop/login.vue?macro=true";
import { default as brandsP6OoKNeQQVMeta } from "/vercel/path0/pages/[[lang]]/shop/register/brands.vue?macro=true";
import { default as indexWuU6BlaWUxMeta } from "/vercel/path0/pages/[[lang]]/shop/register/index.vue?macro=true";
import { default as confirmationx3bhVHpkQOMeta } from "/vercel/path0/pages/[[lang]]/shop/register/individual/confirmation.vue?macro=true";
import { default as indexR0uc2hvK6eMeta } from "/vercel/path0/pages/[[lang]]/shop/register/individual/index.vue?macro=true";
import { default as confirmationN4h7FeRVMSMeta } from "/vercel/path0/pages/[[lang]]/shop/register/professional/confirmation.vue?macro=true";
import { default as index4jQPlW7aHqMeta } from "/vercel/path0/pages/[[lang]]/shop/register/professional/index.vue?macro=true";
import { default as wishlistbXmpR4S4OiMeta } from "/vercel/path0/pages/[[lang]]/shop/wishlist.vue?macro=true";
import { default as component_45stubPq486Y6H6eMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.17.4_encoding@0.1.13_eslint@8.57.1_ioredis@5_lulxhjz3padjop72p7pvexz5eq/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubPq486Y6H6e } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.17.4_encoding@0.1.13_eslint@8.57.1_ioredis@5_lulxhjz3padjop72p7pvexz5eq/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "uri___de",
    path: "/:uri(.*)*",
    component: () => import("/vercel/path0/pages/[...uri].vue").then(m => m.default || m)
  },
  {
    name: "uri___fr",
    path: "/fr/:uri(.*)*",
    component: () => import("/vercel/path0/pages/[...uri].vue").then(m => m.default || m)
  },
  {
    name: "uri___it",
    path: "/it/:uri(.*)*",
    component: () => import("/vercel/path0/pages/[...uri].vue").then(m => m.default || m)
  },
  {
    name: "uri___en",
    path: "/en/:uri(.*)*",
    component: () => import("/vercel/path0/pages/[...uri].vue").then(m => m.default || m)
  },
  {
    name: "lang-search___de",
    path: "/:lang?/search",
    component: () => import("/vercel/path0/pages/[[lang]]/search.vue").then(m => m.default || m)
  },
  {
    name: "lang-search___fr",
    path: "/fr/:lang?/search",
    component: () => import("/vercel/path0/pages/[[lang]]/search.vue").then(m => m.default || m)
  },
  {
    name: "lang-search___it",
    path: "/it/:lang?/search",
    component: () => import("/vercel/path0/pages/[[lang]]/search.vue").then(m => m.default || m)
  },
  {
    name: "lang-search___en",
    path: "/en/:lang?/search",
    component: () => import("/vercel/path0/pages/[[lang]]/search.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-all___de",
    path: "/:lang?/shop/:all(.*)*",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/[...all].vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-all___fr",
    path: "/fr/:lang?/shop/:all(.*)*",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/[...all].vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-all___it",
    path: "/it/:lang?/shop/:all(.*)*",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/[...all].vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-all___en",
    path: "/en/:lang?/shop/:all(.*)*",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/[...all].vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-address___de",
    path: "/:lang?/shop/account/address",
    meta: address2YPObEUJW8Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/address.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-address___fr",
    path: "/fr/:lang?/shop/account/address",
    meta: address2YPObEUJW8Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/address.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-address___it",
    path: "/it/:lang?/shop/account/address",
    meta: address2YPObEUJW8Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/address.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-address___en",
    path: "/en/:lang?/shop/account/address",
    meta: address2YPObEUJW8Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/address.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-brands___de",
    path: "/:lang?/shop/account/brands",
    meta: brandszsXuVXGUHFMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/brands.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-brands___fr",
    path: "/fr/:lang?/shop/account/brands",
    meta: brandszsXuVXGUHFMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/brands.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-brands___it",
    path: "/it/:lang?/shop/account/brands",
    meta: brandszsXuVXGUHFMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/brands.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-brands___en",
    path: "/en/:lang?/shop/account/brands",
    meta: brandszsXuVXGUHFMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/brands.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-downloads-id___de",
    path: "/:lang?/shop/account/downloads/:id?",
    meta: _91_91id_93_930WiuAkUeCYMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/downloads/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-downloads-id___fr",
    path: "/fr/:lang?/shop/account/downloads/:id?",
    meta: _91_91id_93_930WiuAkUeCYMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/downloads/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-downloads-id___it",
    path: "/it/:lang?/shop/account/downloads/:id?",
    meta: _91_91id_93_930WiuAkUeCYMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/downloads/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-downloads-id___en",
    path: "/en/:lang?/shop/account/downloads/:id?",
    meta: _91_91id_93_930WiuAkUeCYMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/downloads/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-orders-id___de",
    path: "/:lang?/shop/account/orders/:id()",
    meta: _91id_93zT4oyjsEaCMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-orders-id___fr",
    path: "/fr/:lang?/shop/account/orders/:id()",
    meta: _91id_93zT4oyjsEaCMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-orders-id___it",
    path: "/it/:lang?/shop/account/orders/:id()",
    meta: _91id_93zT4oyjsEaCMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-orders-id___en",
    path: "/en/:lang?/shop/account/orders/:id()",
    meta: _91id_93zT4oyjsEaCMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-orders___de",
    path: "/:lang?/shop/account/orders",
    meta: indexM4N6U1O4szMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-orders___fr",
    path: "/fr/:lang?/shop/account/orders",
    meta: indexM4N6U1O4szMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-orders___it",
    path: "/it/:lang?/shop/account/orders",
    meta: indexM4N6U1O4szMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-orders___en",
    path: "/en/:lang?/shop/account/orders",
    meta: indexM4N6U1O4szMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-profile___de",
    path: "/:lang?/shop/account/profile",
    meta: profiletlLTQuDee2Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-profile___fr",
    path: "/fr/:lang?/shop/account/profile",
    meta: profiletlLTQuDee2Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-profile___it",
    path: "/it/:lang?/shop/account/profile",
    meta: profiletlLTQuDee2Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-profile___en",
    path: "/en/:lang?/shop/account/profile",
    meta: profiletlLTQuDee2Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-recover___de",
    path: "/:lang?/shop/account/recover",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/recover/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-recover___fr",
    path: "/fr/:lang?/shop/account/recover",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/recover/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-recover___it",
    path: "/it/:lang?/shop/account/recover",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/recover/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-recover___en",
    path: "/en/:lang?/shop/account/recover",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/recover/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-recover-password___de",
    path: "/:lang?/shop/account/recover/password",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/recover/password.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-recover-password___fr",
    path: "/fr/:lang?/shop/account/recover/password",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/recover/password.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-recover-password___it",
    path: "/it/:lang?/shop/account/recover/password",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/recover/password.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-recover-password___en",
    path: "/en/:lang?/shop/account/recover/password",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/recover/password.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-id-failure___de",
    path: "/:lang?/shop/checkout/:id()/failure",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/[id]/failure.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-id-failure___fr",
    path: "/fr/:lang?/shop/checkout/:id()/failure",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/[id]/failure.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-id-failure___it",
    path: "/it/:lang?/shop/checkout/:id()/failure",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/[id]/failure.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-id-failure___en",
    path: "/en/:lang?/shop/checkout/:id()/failure",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/[id]/failure.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-id-success___de",
    path: "/:lang?/shop/checkout/:id()/success",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-id-success___fr",
    path: "/fr/:lang?/shop/checkout/:id()/success",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-id-success___it",
    path: "/it/:lang?/shop/checkout/:id()/success",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-id-success___en",
    path: "/en/:lang?/shop/checkout/:id()/success",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-cart___de",
    path: "/:lang?/shop/checkout/cart",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-cart___fr",
    path: "/fr/:lang?/shop/checkout/cart",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-cart___it",
    path: "/it/:lang?/shop/checkout/cart",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-cart___en",
    path: "/en/:lang?/shop/checkout/cart",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout___de",
    path: "/:lang?/shop/checkout",
    meta: indexNTWEvTkKWSMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout___fr",
    path: "/fr/:lang?/shop/checkout",
    meta: indexNTWEvTkKWSMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout___it",
    path: "/it/:lang?/shop/checkout",
    meta: indexNTWEvTkKWSMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout___en",
    path: "/en/:lang?/shop/checkout",
    meta: indexNTWEvTkKWSMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-login___de",
    path: "/:lang?/shop/login",
    meta: loginHoMyJwtuv2Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/login.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-login___fr",
    path: "/fr/:lang?/shop/login",
    meta: loginHoMyJwtuv2Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/login.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-login___it",
    path: "/it/:lang?/shop/login",
    meta: loginHoMyJwtuv2Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/login.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-login___en",
    path: "/en/:lang?/shop/login",
    meta: loginHoMyJwtuv2Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/login.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-brands___de",
    path: "/:lang?/shop/register/brands",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/brands.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-brands___fr",
    path: "/fr/:lang?/shop/register/brands",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/brands.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-brands___it",
    path: "/it/:lang?/shop/register/brands",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/brands.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-brands___en",
    path: "/en/:lang?/shop/register/brands",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/brands.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register___de",
    path: "/:lang?/shop/register",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register___fr",
    path: "/fr/:lang?/shop/register",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register___it",
    path: "/it/:lang?/shop/register",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register___en",
    path: "/en/:lang?/shop/register",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-individual-confirmation___de",
    path: "/:lang?/shop/register/individual/confirmation",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/individual/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-individual-confirmation___fr",
    path: "/fr/:lang?/shop/register/individual/confirmation",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/individual/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-individual-confirmation___it",
    path: "/it/:lang?/shop/register/individual/confirmation",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/individual/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-individual-confirmation___en",
    path: "/en/:lang?/shop/register/individual/confirmation",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/individual/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-individual___de",
    path: "/:lang?/shop/register/individual",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/individual/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-individual___fr",
    path: "/fr/:lang?/shop/register/individual",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/individual/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-individual___it",
    path: "/it/:lang?/shop/register/individual",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/individual/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-individual___en",
    path: "/en/:lang?/shop/register/individual",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/individual/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-professional-confirmation___de",
    path: "/:lang?/shop/register/professional/confirmation",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/professional/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-professional-confirmation___fr",
    path: "/fr/:lang?/shop/register/professional/confirmation",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/professional/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-professional-confirmation___it",
    path: "/it/:lang?/shop/register/professional/confirmation",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/professional/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-professional-confirmation___en",
    path: "/en/:lang?/shop/register/professional/confirmation",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/professional/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-professional___de",
    path: "/:lang?/shop/register/professional",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/professional/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-professional___fr",
    path: "/fr/:lang?/shop/register/professional",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/professional/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-professional___it",
    path: "/it/:lang?/shop/register/professional",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/professional/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-register-professional___en",
    path: "/en/:lang?/shop/register/professional",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/register/professional/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-wishlist___de",
    path: "/:lang?/shop/wishlist",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-wishlist___fr",
    path: "/fr/:lang?/shop/wishlist",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-wishlist___it",
    path: "/it/:lang?/shop/wishlist",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-wishlist___en",
    path: "/en/:lang?/shop/wishlist",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/wishlist.vue").then(m => m.default || m)
  },
  {
    name: component_45stubPq486Y6H6eMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubPq486Y6H6e
  }
]